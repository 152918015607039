<template>
  <sidenav
    :custom_class="this.$store.state.mcolor"
    :class="[
      this.$store.state.isTransparent,
      this.$store.state.isRTL ? 'fixed-end' : 'fixed-start',
    ]"
    v-if="this.$store.state.showSidenav && !inapp"
  />
  <main
    class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
    :style="this.$store.state.isRTL ? 'overflow-x: hidden' : ''"
  >
    <!-- nav -->
    <navbar
      :class="[navClasses]"
      :textWhite="this.$store.state.isAbsolute ? 'text-white opacity-8' : ''"
      :minNav="navbarMinimize"
      v-if="this.$store.state.showNavbar && !inapp "
    />
    <div class="loader"  v-if='this.mainLoader' >
      <loader />
    <p style="opacity: 0.5; font-size:13px" class="pt-2">loading ...</p>

    </div>

    <router-view v-else />
    

    <!-- <app-footer v-show="this.$store.state.showFooter" /> -->
    <topup
      :toggle="toggleConfigurator"
      :class="[
        this.$store.state.showConfig ? 'show' : '',
        this.$store.state.hideConfigButton ? 'd-none' : '',
      ]"
    />
    <topup-own
      :toggle="topUpOwn"
      :class="[
        this.$store.state.topUpOwn ? 'show' : '',
        this.$store.state.hideConfigButton ? 'd-none' : '',
      ]"
    />
    <withdraw
      :toggle="toggleWithdraw"
      :class="[
        this.$store.state.withdraw ? 'show' : '',
        this.$store.state.hideConfigButton ? 'd-none' : '',
      ]"
    />
    <topup-crypto
      :toggle="toggleTopUpCrypto"
      :class="[
        this.$store.state.topUpCrypto ? 'show' : '',
        this.$store.state.hideConfigButton ? 'd-none' : '',
      ]"
    />
    <reinvest
      :toggle="toggleReinvest"
      :class="[
        this.$store.state.reinvest ? 'show' : '',
        this.$store.state.hideConfigButton ? 'd-none' : '',
      ]"
    />

  <!-- <mobile-nav ></mobile-nav> -->
  <!-- <mobile-nav v-if="isMobile() && authenticated && showBottomBar"></mobile-nav> -->
  </main>
</template>

<style>
/* 400 */


.yellow-gradient-bg{
  
  background: radial-gradient(50% 50% at 50% 50%, #FAB23C 0%, #E1A050 100%);;
} 
.loader{
  z-index:9999;
  top:0;
  left:0;
  text-align: center;
  position: fixed;
  background: #f8f9fa;/* linear-gradient(0deg, #f8f9fa, #f8f9fa), radial-gradient(112.49% 34.48% at 46.93% 30.17%, rgba(170, 168, 168, 0.2) 0%, rgba(176, 176, 176, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  height: 100vh;
  width: 100vw;
  display: flex;
  height:100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
}
.btn{
  min-height: 40px;
}

</style>
<script>
import Sidenav from "./examples/Sidenav";
import loader from "./views/components/Loader.vue";
import Topup from "@/examples/Topup.vue";
import TopupOwn from "@/examples/TopupOwn.vue";
import Reinvest from "@/examples/Reinvest.vue";
import TopupCrypto from "@/examples/TopupCrypto.vue";
import Withdraw from "@/examples/Withdraw.vue";
import Navbar from "@/examples/Navbars/Navbar.vue";
// import MobileNav from "@/examples/Navbars/MobileNav.vue";
// import AppFooter from "@/examples/Footer.vue";
import { mapMutations, mapGetters, mapActions } from "vuex";
export default {
  name: "App",
  components: {
    Sidenav,
    Topup,
    Withdraw,
    TopupCrypto,
    Reinvest,
    Navbar,
    // AppFooter,
    loader,
    // MobileNav,
    TopupOwn
  },
  methods: {
    ...mapMutations([
    "toggleConfigurator",
    "toggleWithdraw", 
    "navbarMinimize",
    "toggleTopUpCrypto",
    "toggleTopUpOwn",
    "toggleReinvest"]),
    ...mapActions(["auth_by_query","getUserData"]),
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
    
  },
  watch:{
    withdraw_curr_bot(val){
      if (val === true) this.getUserData()
    },
    showConfig(val){
      if (val === true) this.getUserData()
    },
    topUpCrypto(val){
      if (val === true) this.getUserData()
    },
    withdraw(val){
      if (val === true) this.getUserData()
    },
    topUpOwn(val){
      if (val === true) this.getUserData()
    },
      authenticated(val){
        if (val){
          if (this.$router.currentRoute.value.path.includes('sign')) {
          // this.getUserPlot()
          this.$router.push('/dashboard')
          }
        }
        else{
          if (!this.$router.currentRoute.value.path.includes('sign'))  {

            if(this.$route.query.token){
               this.auth_by_query({vm:this,token:this.$route.query.token});
            }
            else{
              this.$router.push('/sign-in')
              this.$toast.info(this.$t('toast.login_to_account') , { });
            }
            
            
          }
        }
      },
      $route (to, from){
        
        if (!(to.path.includes('sign')) && !this.authenticated){
          if(this.$route.query.token){
              this.auth_by_query({vm:this,token:this.$route.query.token});
            }
          else{
            this.$toast.info(this.$t('toast.login_to_account'), { });
            this.$router.push('/sign-in')

          }
            
        }
        // if (to.path!=from.path && this.isMobile()){
        //   this.navbarMinimize()
        // }

        if (to.path.includes('profile') && !from.path.includes('profile')){
          this.getUserData()
        }

        // if (to.path.includes('dashboard') && !from.path.includes('dashboard')){
        //   this.getUserPlot()
        // }
        

        
        
    }
  },
  computed: {
    ...mapGetters(['authenticated','user','mainLoader',
      'withdraw_curr_bot',
      'showConfig',
      'topUpCrypto',
      'withdraw',
      'topUpOwn',
  ]),
    inapp(){
      if (this.$route.path.includes('inapp')){
        return true
      }
      return false
    },

    navClasses() {
      return {
        "position-sticky blur shadow-blur mt-4 left-auto top-1 z-index-sticky": this
          .$store.state.isNavFixed,
        "position-absolute px-4 mx-0 w-100 z-index-2": this.$store.state
          .isAbsolute,
        "px-0 mx-3": !this.$store.state.isAbsolute,
      };
    },
    showBottomBar() {
      if (window.matchMedia('(display-mode: standalone)').matches) {  
          // do things here  
          // set a variable to be used when calling something  
          // e.g. call Google Analytics to track standalone use   
          return true;
      }  

      return false;
    },
  },
  mounted(){
    setTimeout(() => {
      if (this.$route.query.token){
          if (this.$route.query.token != localStorage.getItem('token')){
            console.log('lets reload', this.$route.query.token,localStorage.getItem('token'))
            if (!this.$router.currentRoute.value.path.includes('sign'))  {
              this.auth_by_query({vm:this,token:this.$route.query.token});
              setTimeout(() => {
                    location.reload();
                }, 1000);
            }
          }
        }
      }, 2000);

  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
    setTimeout(() => {
      if (localStorage.getItem('token') ){
        if (this.$route.query.token){
          this.auth_by_query({vm:this,token:this.$route.query.token});
          setTimeout(() => {
            this.getUserData()  
          }, 100);
        }
        if (localStorage.getItem('token').length > 5 && !this.$route.query.token) {
          console.log(247)
          this.$store.state.authenticated = true
          this.getUserData()  
        }
    }
    else{
      console.log(252)
      if(this.$route.query.token){
        console.log(254)
        this.auth_by_query({vm:this,token:this.$route.query.token});
        setTimeout(() => {
          this.getUserData()  
        }, 100);
      }
    }
      }, 1000);
  },
};
</script>
